/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

const MenuMobile = ({ data, location, active }) => {
  const { menuLinks } = data.site.siteMetadata;
  return (
    <div
      id="main-menu-mobile"
      className={`main-menu-mobile ${active ? 'open' : ''}`}
    >
      <ul>
        {menuLinks.map((link) => {
          let activeState = location.pathname.includes(link.link);
          if (link.link === '/' && location.pathname !== '/') {
            activeState = false;
          }
          const activeClass = activeState ? 'active' : '';

          if (link.link.includes('http')) {
            return (
              <li
                key={link.name}
                onClick={() => {
                  trackCustomEvent({
                    category: "Mobile Menu",
                    action: "Click",
                    label: `${link.name} - ${link.link}`,
                  });
                }}
              >
                <a href={link.link} target="_blank" rel="noopener noreferrer">{link.name}</a>
              </li>
            );
          }
          return (
            <li
              key={link.name}
              onClick={() => {
                trackCustomEvent({
                  category: "Mobile Menu",
                  action: "Click",
                  label: `${link.name} - ${link.link}`,
                });
              }}
            >
              <Link className={activeClass} to={link.link}>{link.name}</Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query MenuMobileQuery {
        site {
          siteMetadata {
            menuLinks {
              name
              link
            }
          }
        }
      }
    `}
    render={data => <MenuMobile active={props.active} data={data} location={props.location} />}
  />
);
