import React from 'react';
import { Link, withPrefix } from 'gatsby';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import Menu from './Menu';
import Hamburger from './Hamburger';
import MenuMobile from './MenuMobile';

const GALogoClick = () => (
  trackCustomEvent({
    category: "Header",
    action: "Logo Click",
    label: `Overview - /`,
  })
);

const GAMobileLogoClick = () => (
  trackCustomEvent({
    category: "Mobile Header",
    action: "Logo Click",
    label: `Overview - /`,
  })
);

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuActive: false,
    };
  }

  toggleMenu = () => {
    this.setState(prevState => ({
      menuActive: !prevState.menuActive,
    }));
  };

  render() {
    return (
      <header className="header sticky-top">
        <div className="wrapper">
          <div className="logo" onClick={GALogoClick}>
            <Link to="/">
              <img src={withPrefix('/logos/logo-rise-white.svg')} alt="Rise Prize" />
            </Link>
          </div>
          <div className="logo-mobile" onClick={GAMobileLogoClick}>
            <Link to="/">
              <img src={withPrefix('/logos/logo-rise-white.svg')} alt="Rise Prize" />
            </Link>
          </div>
          <MenuMobile active={this.state.menuActive} location={this.props.location} />
          <Menu location={this.props.location} />
          <Hamburger toggleMenu={this.toggleMenu} />
        </div>
      </header>
    );
  }
}

export default Header;
