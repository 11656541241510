import React from 'react';
import {
  graphql, Link, StaticQuery, withPrefix,
} from 'gatsby';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

const Footer = () => (
  <footer className="footer-strip text-white">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="footer-company pt-1">
            <img className="footer-logo" src={withPrefix('/logos/logo-rise-stack-white.svg')} alt="Rise Prize"/>
            <br/>
            <p className="footer-subtitle">Awarding $1.5M in combined prizes to solutions accelerating postsecondary success for parents.</p>
          </div>
        </div>
        <div className="col-12 pt-3 pt-md-0 col-md-6 align-self-center">
          <div className="row">
            <div className="col-6 offset-md-6 align-self-center">
              <div className="footer">
                <ul className="footer-menu">
                  <li
                    onClick={() => {
                      trackCustomEvent({
                        category: "Footer",
                        action: "Link Click",
                        label: `Overview - /`,
                      });
                    }}
                  >
                    {' '}
                    <Link to="/">Overview</Link>
                  </li>
                  <li
                    onClick={() => {
                      trackCustomEvent({
                        category: "Footer",
                        action: "Link Click",
                        label: `About The Rise Prize - /about-the-rise-prize`,
                      });
                    }}
                  >
                    {' '}
                    <Link to="/about-the-rise-prize">About The Rise Prize</Link>
                  </li>
                  <li
                    onClick={() => {
                      trackCustomEvent({
                        category: "Footer",
                        action: "Link Click",
                        label: `About Risers - /about-risers`,
                      });
                    }}
                  >
                    {' '}
                    <Link to="/about-risers">About Risers</Link>
                  </li>
                  <li
                    onClick={() => {
                      trackCustomEvent({
                        category: "Footer",
                        action: "Link Click",
                        label: `Our Partners - /our-partners`,
                      });
                    }}
                  >
                    {' '}
                    <Link to="/our-partners">Our Partners</Link>
                  </li>
                  <li
                    onClick={() => {
                      trackCustomEvent({
                        category: "Footer",
                        action: "Link Click",
                        label: `Contact Us`,
                      });
                    }}
                  >
                    {' '}
                    <a href="mailto:info@theriseprize.com?subject=The Rise Prize" target="_blank" rel="noreferrer noopener">Contact Us</a>
                  </li>
                </ul>
              </div>
              <ul className="pt-3 social social-invert">
                <li
                  className="social-twitter"
                  onClick={() => {
                    trackCustomEvent({
                      category: "Footer",
                      action: "Social Click",
                      label: `Twitter`,
                    });
                  }}
                >
                  <a href="https://www.twitter.com/imaginablefut" target="_blank" rel="noopener noreferrer">
                    <span className="sr-only">Twitter</span>
                  </a>
                </li>
                <li
                  className="social-facebook"
                  onClick={() => {
                    trackCustomEvent({
                      category: "Footer",
                      action: "Social Click",
                      label: `Facebook`,
                    });
                  }}
                >
                  <a href="https://www.facebook.com/imaginablefutures" target="_blank" rel="noopener noreferrer">
                    <span className="sr-only">Facebook</span>
                  </a>
                </li>
                <li
                  className="social-linkedin"
                  onClick={() => {
                    trackCustomEvent({
                      category: "Footer",
                      action: "Social Click",
                      label: `LinkedIn`,
                    });
                  }}
                >
                  <a href="https://linkedin.com/company/imaginable-futures" target="_blank" rel="noopener noreferrer">
                    <span className="sr-only">LinkedIn</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-12 pt-3">
          <p className="privacy-and-terms">
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/terms-of-use">Terms of Use</Link>
          </p>
          <p className="copyright">
            ©&nbsp;Copyright&nbsp;
            {new Date().getFullYear()}
            &nbsp;
            Entangled Institute. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  </footer>
);

export default () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => <Footer data={data} />}
  />
);
