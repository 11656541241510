import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql, withAssetPrefix } from 'gatsby';

const SEO = props => (
  <StaticQuery
    query={detailsQuery}
    render={(data) => {
      const title = props.title || data.site.siteMetadata.title;
      const description = [];
      if (!props.meta.some(e => e.name === 'description')) {
        description.push({ name: 'description', content: "Awarding $1.5M in combined prizes to solutions accelerating postsecondary success for parents." });
      }

      return (
        <Helmet
          htmlAttributes={{
            lang: props.lang,
          }}
          title={title}
          titleTemplate={`%s - ${data.site.siteMetadata.title}`}
          meta={[
            { name: "google-site-verification", content: "9oSfrKagBkUfHv2yKiAezbZeLnkhJM7Iv_R45mYnctc" },
            { property: "og:type", content: "website" },
            { property: "og:url", content: "https://theriseprize.com" },
            { property: "og:title", content: `${title} - ${data.site.siteMetadata.title}` },
            { property: "og:description", content: `Awarding $1.5M in combined prizes to solutions accelerating postsecondary success for parents.` },
            { property: "og:image", content: `https://theriseprize.com${withAssetPrefix('social/twitter-image.jpg')}` },
            { name: "twitter:card", content: "summary_large_image" },
            { name: "twitter:title", content: `${title} - ${data.site.siteMetadata.title}` },
            { name: "twitter:description", content: `Awarding $1.5M in combined prizes to solutions accelerating postsecondary success for parents.` },
            { name: "twitter:image", content: `https://theriseprize.com${withAssetPrefix('social/twitter-image.jpg')}` },
            { name: "google-site-verification", content: "9oSfrKagBkUfHv2yKiAezbZeLnkhJM7Iv_R45mYnctc" },
            ...props.meta,
            ...description,
          ]}
        />
      );
    }}
  />
);

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
