import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

const Menu = ({ data, location }) => {
  const { menuLinks } = data.site.siteMetadata;
  return (
    <div id="main-menu" className="main-menu">
      <ul>
        {menuLinks.map((link) => {
          let activeState = location.pathname.includes(link.link);
          if (link.link === '/' && location.pathname !== '/') {
            activeState = false;
          }
          const activeClass = activeState ? 'active' : '';

          if (link.link.includes('http')) {
            return (
              <li
                key={link.name}
                onClick={() => {
                  trackCustomEvent({
                    category: "Menu",
                    action: "Click",
                    label: `${link.name} - ${link.link}`,
                  });
                }}
              >
                <a className="btn btn-menu__apply" href={link.link} target="_blank" rel="noopener noreferrer">
                  {link.name}
                </a>
              </li>
            );
          }
          return (
            <li
              key={link.name}
              className={activeClass}
              onClick={() => {
                trackCustomEvent({
                  category: "Menu",
                  action: "Click",
                  label: `${link.name} - ${link.link}`,
                });
              }}
              >
              <Link to={link.link}>{link.name}</Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            menuLinks {
              name
              link
            }
          }
        }
      }
    `}
    render={data => <Menu data={data} location={props.location} />}
  />
);
